.status-label-not-planned, .status-label-closed {
  background-color: #e9ebed;
  color: #5f5f5f;
}

.status-label-pending, .status-label-pending-moderation {
  background-color: #1f73b7;
  text-align: center;
}

.status-label-open {
  background-color: #c72a1c;
}

.status-label-solved {
  background-color: #68737d;
}

.status-label-new {
  background-color: #ffb648;
  color: #703b15;
}

.status-label-hold {
  background-color: #000;
}
